<template>
  <main>
    <div class="container">
      <div class="col-sm-8 mx-auto py-5">
        <div class="bg-white p-4 rounded-lg shadow-md">
          <div class="row">
            <div class="col-sm-12">
              <!--<div class="d-flex justify-content-center align-items-center mb-4">
                <div class="bg-primary style_level d-flex justify-content-center align-items-center">
                  <h1 class="display-4 mb-0 text-white">{{ currentStep + 1 }}<span style="font-size: 25px;">/{{ totalSteps }}</span></h1>
                </div>
              </div> -->
              <div class="text-center mb-4">
                <h4 class="text-primary">
                  Enregistrement du besion en personnel
                </h4>
                <!-- <h6 class="text-muted">Je suis une <a href="inscription_2.html" class="badge bg-success text-white">BesoinPpersonnel</a></h6> -->
                <!--<h6 class="text-muted">Etape {{ currentStep + 1 }}/{{ steps[currentStep].title }} </h6> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div v-for="(step, index) in steps" :key="index">
                <component
                  v-model="besoinPersonnel"
                  v-if="index == currentStep"
                  :ref="`currentTab_${index}`"
                  :is="step.component"
                />
              </div>

              <div class="row">
                <div class="col">
                  <div class="d-grid gap-2 my-4">
                    <button
                      v-if="!isFirstStep"
                      @click.prevent="previousStep"
                      type="button"
                      class="btn btn-outline-primary btn-lg"
                    >
                      Précédent
                    </button>
                  </div>
                </div>
                <div class="col">
                  <div class="d-grid gap-2 my-4">
                    <button
                      v-if="!isLastStep && !estPeuOuPasInstruit"
                      @click.prevent="nextStep"
                      type="submit"
                      class="btn btn-primary btn-lg"
                    >
                      Suivant
                    </button>
                    <button
                      @click.prevent="submitForm"
                      v-else
                      type="submit"
                      class="btn btn-primary btn-lg"
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
              <!-- <h6 class="text-muted text-center">J'ai un compte candidat <a href="connexion.html" class="text-primary">connectez-vous</a></h6> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapMutations } from "vuex";
// import OffreEditorForm from "../../../components/espace/entreprise/offre/steps/OffreEditorForm.vue";
import BesoinPersonnel from "../../../components/espace/entreprise/fiche/steps/BesoinPersonnel.vue";

// import DisponibiliteActuelle from '@/components/register/offre/steps/DisponibiliteActuelle.vue'

export default {
  data() {
    return {
      currentStep: 0,
      besoinPersonnel: [],
      error: false,
      steps: [
        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Ajout du besoin de personnel",
          // subtitle: 'Activité ',
          component: BesoinPersonnel,
          hideFooter: true,
          hiddeHeader: false,
        },
      ],
    };
  },
  created() {
    // this.besoinpersonnel = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.besoinpersonnel
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
  },
  watch: {
    besoinPersonnel() {
      localStorage.setItem(
        "subscription",
        JSON.stringify(this.besoinPersonnel)
      );
    },
    currentStep() {
      localStorage.setItem("currentStep", this.currentStep);
    },
    // "besoinPersonnel.phone": {
    //   handler() {
    //     this.error = false;
    //   },
    // },
  },
  computed: {
    totalSteps() {
      return this.steps.length;
    },
    isFirstStep() {
      return this.currentStep === 0;
    },
    isLastStep() {
      return this.currentStep === this.totalSteps - 1;
    },
    hiddeHeader() {
      return this.steps[this.currentStep].hiddeHeader;
    },
    hideFooter() {
      return this.steps[this.currentStep].hideFooter;
    },
    estPeuOuPasInstruit() {
      return (
        this.besoinpersonnel.estInstruit != null &&
        (!this.besoinpersonnel.estInstruit ||
          (this.besoinpersonnel.estInstruit &&
            this.besoinpersonnel.niveauInstruction != null &&
            this.besoinpersonnel.niveauInstruction <= 10))
      );
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateBesoinPersonnel: "besoin/createOrUpdateBesoinPersonnel",
    }),
    ...mapMutations({
      addBesoinPersonnel: "besoinpersonnel/addBesoinPersonnel",
    }),
    resetForm() {
      localStorage.removeItem("subscription");
      localStorage.removeItem("currentStep");
    },
    async submitForm() {
      if (await this.validateStep(this.currentStep)) {
        this.besoinPersonnel = {
          ...this.besoinPersonnel,
          // languages: [
          //   {
          //     language: {
          //       id: 1,
          //       createdDate: "0001-01-01T03:06:03Z",
          //       nom: "FON",
          //     },
          //   },
          // ],
        };
        this.createOrUpdateBesoinPersonnel(this.besoinPersonnel)
          .then(async (de) => {
            // const res = await this.$dialog.confirm({ text: `Vous-vous immédiatement procéder au remplissage de la fiche Azoli de ${de.nom} ${de.prenom}?` })
            this.$toast.success("Opération réussie!", {
              position: "bottom-right",
              duration: 5000,
            });
            console.log(de);
            this.$router.push({ name: "espace.entreprise.besoin.list" });
          })
          .catch(() => {
            this.error = true;
            this.$toast.error(`Erreur d'ajout de besoin personnel.`, {
              position: "bottom-right",
              duration: 35000,
            });
          });
      }
    },
    previousStep() {
      if (this.isFirstStep) return;
      this.currentStep--;
    },
    async nextStep() {
      if (this.isLastStep) return;
      if (await this.validateStep(this.currentStep)) {
        this.currentStep++;
      }
    },
    async validateStep(step) {
      this.addBesoinPersonnel(this.besoinPersonnel);
      console.log(step);
      console.log(this.besoinPersonnel);
      console.log(this.$refs[`currentTab_${step}`][0]);
      let target = this.$refs[`currentTab_${step}`][0];
      return target.isCompleted();
      // return this.$refs[`currentTab_${step}`][0].do()
    },
  },
};
</script>

<style>
.vue-form-wizard .wizard-progress-with-circle,
.vue-form-wizard .wizard-header,
.vue-form-wizard .wizard-nav.wizard-nav-pills {
  display: none;
}

.wizard-progress-with-circle {
  background: rgba(0, 0, 0, 0.2);
}

/* .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  }
  .vue-form-wizard .wizard-icon-circle{
  } */

/* .main-wizard {
    min-height: 60vh;
  } */
</style>
